<template>
    <div class="ai-disclaimer">
        <div class="row">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex mb-3 align-items-center">
                    <!-- <img src="@/assets/images/x-twitter.svg" alt="Twitter Logo" width="16" > -->
                    <img alt="RegAlytics Logo" src="@/assets/images/icon.svg" width="40">
                    <h3 class="ms-3">AI Disclaimer</h3>
                </div>
                <button class="btn btn-link btn-sm p-0 m-0" @click="goBackPage">Go back</button>
            </div>
        </div>
        <p>At RegAlytics, we are committed to transparency and honesty in all our endeavors. We believe it's essential
            for our users to understand how and where we source our content. To that end, we'd like to inform you of the
            following:</p>

        <h2>Generative AI in Content Production</h2>
        <p>Some of the content you encounter on our website and in our newsletters may be generated using advanced
            generative artificial intelligence (AI) models. These models are designed to produce human-like text based
            on the data they've been trained on.</p>

        <h2>Human Oversight</h2>
        <p>While generative AI can produce high-quality content, it's not infallible. We always have a team of analysts
            and content specialists who review, edit, and approve AI-generated content to ensure its accuracy,
            relevance, and quality.</p>

        <h2>Ethical Use</h2>
        <p>We use AI responsibly. Our primary goal in utilizing AI is to enhance our content offerings and provide
            valuable, timely, and relevant information to our users. We do not use AI-generated content to mislead,
            misinform, or deceive our audience.</p>

        <h2>Feedback Welcomed</h2>
        <p>Your feedback is invaluable to us. If you ever come across content that seems off or not up to our usual
            standards, please let us know. We continually strive to improve and refine our processes, and your insights
            play a crucial role in that.</p>

        <h2>Continuous Learning</h2>
        <p>The world of AI is ever-evolving, and so are we. We are committed to staying updated with the latest
            advancements in AI and ensuring that our practices align with industry standards and best practices.</p>

        <h2>Privacy and Data Protection</h2>
        <p>Rest assured, while we use AI to generate content, we prioritize your privacy. No personal data from our
            users is used to train our AI models or produce content.</p>

        <p>Thank you for being a valued member of our community. We appreciate your trust and understanding as we
            navigate the exciting world of AI in content creation.</p>
    </div>
</template>

<script>
export default {
    name: 'AiDisclaimer',
    methods: {
        goBackPage() {
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
.ai-disclaimer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.ai-disclaimer h1,
.ai-disclaimer h2 {
    color: #333;
}

.ai-disclaimer p {
    margin-bottom: 1em;
}
</style>